import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["spinner", "form"]

    connect() {
        console.log("Connected to the spinner controller");
        this.hideSpinner();
    }

    showSpinner() {
        this.formTarget.classList.add("hidden");
        this.spinnerTarget.classList.remove("hidden");
    }

    hideSpinner() {
        this.formTarget.classList.remove("hidden");
        this.spinnerTarget.classList.add("hidden");
    }
}
